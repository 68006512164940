/* Custom Navbar Styling */
.navbar-custom {
    background-color: #2c3e50;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.basic-navbar-nav {
    margin-left: 20px;
}

.navbar-brand-custom {
  color: #ecf0f1;
  font-size: 1.25rem;
  font-weight: bold;
  margin-left: 20px;
}

.navbar-custom .navbar-toggler {
  border: none;
}

.navbar-custom .nav-link {
  color: #bdc3c7;
  margin-right: 2rem;
  font-size: 1.2rem;
}

.navbar-custom .nav-link:hover {
  color: goldenrod;
}

.navbar-custom .dropdown-menu {
  background-color: #34495e;
  border: none;
}

.navbar-custom .dropdown-item {
  color: #ecf0f1;
}

.navbar-custom .dropdown-item:hover {
  background-color: #2c3e50;
  color: #ecf0f1;
}
