#about {
    padding: 50px 0;
    background-color: #f8f9fa;
}

.about-section {
    padding: 40px 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.about-section h2 {
    font-size: 24px;
    margin-bottom: 15px;
    color: #333;
}

.about-section p {
    font-size: 16px;
    line-height: 1.6;
    color: #555;
}

.about-section ul {
    list-style-type: disc;
    padding-left: 20px;
}

.about-section li {
    margin-bottom: 10px;
}

.about-section .card {
    margin-bottom: 20px;
    width: 100%;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
    min-height: 350px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.about-section .row {
    width: 100%;
    justify-content: space-around;
}

.about-section .card-title {
    font-size: 1.5rem;
    margin-bottom: 10px;
    text-align: center;
}

.about-section .card-text {
    font-size: 1rem;
    line-height: 1.6;
}

.about-section .card h2 {
    text-align: center;
    margin-bottom: 20px;
}

.card {
    border: none; /* Remove default card border */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Light shadow for depth */
}

.card-title {
    font-size: 20px; /* Customize title size */
    color: #333; /* Title color */
}

.card-text {
    font-size: 16px; /* Customize text size */
    color: #555; /* Text color */
}

@media (max-width: 768px) {
    .about-section {
        padding: 15px;
    }

    .about-section h2 {
        font-size: 20px;
    }

    .about-section p {
        font-size: 14px;
    }
}