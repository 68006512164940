.contact-form {
    background-color: #fff;
    border-radius: 8px;
  }
  
  .contact-form h2 {
    font-family: 'Arial', sans-serif;
    font-size: 24px;
    font-weight: bold;
    color: #333;
  }
  
  .contact-form .form-group label {
    font-size: 16px;
    color: #555;
  }
  
  .contact-form .form-control {
    padding: 12px 15px;
    font-size: 14px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    box-shadow: none;
    transition: all 0.3s ease;
  }
  
  .contact-form .form-control:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.25);
  }
  
  .contact-form textarea.form-control {
    resize: none;
  }
  
  .contact-form .btn {
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 4px;
  }
  
  .contact-form .btn:hover {
    background-color: #0056b3;
  }
  
  .form-group input, textarea {
    color: rgb(96, 105, 95);
    opacity: 0.6;
  }