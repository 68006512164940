.footer {
    background-color: #f8f9fa;
    padding: 20px 0;
    bottom: 0;
    width: 100vw;
    position: relative;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer p {
    margin: 0;
    color: #6c757d;
}


