* {
  box-sizing: border-box;
  /*custom for mozilla scrollbar*/
  scrollbar-width: thin;
  scrollbar-color: #555 #f1f1f1;
}

html, body {
  scroll-behavior: smooth;
  font-family: Arial, sans-serif;
  overflow-x: hidden;
}

.container, .row, .col {
  margin: 0;
  padding: 0;
}

h1, h2 {
  margin-bottom: 20px;
}

.hero-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
}

.gallery-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 20px;
  overflow: hidden;
}

.gallery-image {
  width: 100%;
  height: 350px;
  max-width: 350px;
  object-fit: cover;
  margin: 10px;
  transition: transform 0.3s ease;
}

.gallery-image:hover {
  transform: scale(1.1);
  z-index: 1;
}

/*scrollbar custom*/
::-webkit-scrollbar {
  width: 10px;
}

/*thumb custom*/
::-webkit-scrollbar-thumb {
  background-color: #555;
  border-radius: 10px;
}

/*hover effect for thumb*/
::-webkit-scrollbar-thumb:hover {
  background-color: #333;
}

/*Custom track bg*/
::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}


