#downloads {
    padding: 2rem;
    background-color: #f9f9f9;
    border-radius: 10px;
    margin: 2rem 0;
}

#downloads h2 {
    color: #333;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 1.5rem;
}

#downloads p {
    font-size: 1.1rem;
    color: #777;
    margin-bottom: 2rem;
}

ul.list {
    list-style-type: none;
    padding: 0;
}

ul.list li {
    display: inline-block;
    font-size: 1.2rem;
    margin-bottom: 1rem;
    padding: 0.8rem;
    background-color: #007bff;
    border-radius: 5px;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

ul.list li a {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
}

ul.list li:hover {
    background-color: #077b;
    transform: scale(1.02);
}

ul.list li a:hover {
    color: #fff;
}